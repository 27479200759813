<template>
  <b-row class="justify-content-center">
    <b-col md="6" xl="6">
      <b-card class="mb-5">
        <b-overlay :show="loading" rounded="sm">
          <h4 class="my-2">Rekening Bank Tujuan Settlement</h4>

          <b-form-group label="Nama Bank" label-for="bank-name">
            <b-form-input id="bank-name" v-model="form.bank_name" />
          </b-form-group>

          <b-form-group label="No. Rekening" label-for="account-no">
            <b-form-input
              id="account-no"
              type="number"
              v-model="form.account_no"
            />
          </b-form-group>

          <b-form-group label="Nama Rekening" label-for="account-name">
            <b-form-input id="account-name" v-model="form.account_name" />
          </b-form-group>

          <b-button block class="mt-3" variant="success" @click="onSend"
            >Selanjutnya</b-button
          >
        </b-overlay>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BButton,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BButton,
    BOverlay,
  },
  data() {
    return {
      merchantId: null,
      businessType: null,
      loading: false,
      form: {
        bank_name: null,
        account_no: null,
        account_name: null,
      },
    };
  },
  created() {
    this.merchantId = this.$store.state.app.merchantId;

    if (this.$store.state.app.merchantId === null) {
      this.merchantId = localStorage.getItem("merchantId", this.merchantId);
    }

    this.businessType = this.$store.state.app.businessType;

    if (this.$store.state.app.businessType === null) {
      this.businessType = localStorage.getItem(
        "businessType",
        this.businessType
      );
    }
  },
  methods: {
    onSend() {
      if (this.validation()) {
        this.loading = true;
        this.$http
          .post("/merchants/" + this.merchantId + "/bank/add", this.form)
          .then((res) => {
            this.loading = false;
            this.$bvToast.toast("Berhasil", {
              title: "Berhasil",
              variant: "success",
              solid: true,
              autoHideDelay: 1000,
            });
            this.$store.state.app.merchantId = this.merchantId;
            this.$store.state.app.businessType = this.businessType;
            localStorage.setItem("merchantId", this.merchantId);
            localStorage.setItem("businessType", this.businessType);
            this.$router.push("/merchant/add/ktp");
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            this.$bvToast.toast(err.response.data.message, {
              title: "Error",
              variant: "danger",
              solid: true,
              autoHideDelay: 1000,
            });
          });
      }
    },
    makeToast(variant = null) {
      this.$bvToast.toast("Toast body content", {
        title: `Variant ${variant || "default"}`,
        variant,
        solid: true,
      });
    },
    validation() {
      if (this.form.bank_name === null) {
        this.$bvToast.toast("Nama bank tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.account_no === null) {
        this.$bvToast.toast("No. Rekening tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      if (this.form.account_name === null) {
        this.$bvToast.toast("Nama Rekening tidak boleh kosong", {
          title: "Warning",
          variant: "warning",
          solid: true,
          autoHideDelay: 1000,
        });

        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
